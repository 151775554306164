import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@15.1.1-canary.4_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0._w7g4poofhp5hfsl3qqy3ofw6re/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@15.1.1-canary.4_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0._w7g4poofhp5hfsl3qqy3ofw6re/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@15.1.1-canary.4_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0._w7g4poofhp5hfsl3qqy3ofw6re/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@15.1.1-canary.4_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0._w7g4poofhp5hfsl3qqy3ofw6re/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@15.1.1-canary.4_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0._w7g4poofhp5hfsl3qqy3ofw6re/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@15.1.1-canary.4_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0._w7g4poofhp5hfsl3qqy3ofw6re/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@15.1.1-canary.4_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0._w7g4poofhp5hfsl3qqy3ofw6re/node_modules/next/dist/lib/metadata/metadata-boundary.js");
